import React, { Component } from "react";
import SiteDataContext from "../context/SiteDataContext";
import { graphql } from "gatsby";
import { Layout } from "../components/Layout/layout";
import Wrapper from "../components/Layout/Wrapper/wrapper";
import { Helmet } from "react-helmet";
import illustration from "../images/illustration/about-us.svg";
import { Title } from "../components/atoms/Title/title";
import RichText from "../components/organisms/RichText/richText";
import(`./about/about.scss`);

class AboutTemplate extends Component {
  static contextType = SiteDataContext;

  render() {
    const page = this.props.data.contentfulAboutUs;

    return (
      <Layout hideMenuButton={false}>
        <Helmet>
          <title>{page.title}</title>
          <meta
            name="description"
            content={page.metadescription}
          />
        </Helmet>
        <div>
          <Wrapper paddingTop>
            <Title
              hero
              tag="h1"
              content={page.title}
            />
            <div className="about">
              <div className="about__wrapper">
                <div className="about__main">
                  <div className="about__title">
                    <Title
                      regular
                      content={page.subtitle}
                    />
                  </div>
                  <RichText
                    content={page.content}
                    siteData={this.context}
                  />
                </div>
                <div className="about__aside">
                  <img
                    src={illustration}
                    alt={page.title}
                  />
                </div>
              </div>
            </div>
          </Wrapper>
        </div>
      </Layout>
    );
  }
}

export default AboutTemplate;

export const pageQuery = graphql`
  query ($slug: String) {
    contentfulAboutUs(slug: { eq: $slug }) {
      title
      metadescription
      subtitle
      content {
        raw
      }
    }
  }
`;
